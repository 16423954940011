import React from "react";
import { torOnionLogo } from "../../assets/images/logos";
import { checkIcon, crossIcon, internetIcon } from "../../assets/images/icons";

const linksData = [
  {
    href: "https://www.exploit-db.com/",
    title: "Items found from hacks / vulnerabilities",
    text: "exploit-db.com",
    status: "Online",
  },
  {
    href: "http://g66ol3eb5ujdckzqqfmjsbpdjufmjd5nsgdipvxmsh7rckzlhywlzlqd.onion",
    title: "Dark web Messenger",
    text: "DREAD",
    status: "Online",
  },
  {
    href: "http://darkfailenbsdla5mal2mxn2uz66od5vtzd5qozslagrfzachha3f3id.onion/",
    title: "Dark Web Links",
    text: "dark.fail",
    status: "Online",
  },
  {
    href: "http://piratebayo3klnzokct3wt5yyxb2vpebbuyjl7m623iaxmqhsd52coid.onion",
    title: "Pirated Stuff",
    text: "PirateBay",
    status: "Online",
  },
  {
    href: "http://ddosxlvzzow7scc7egy75gpke54hgbg2frahxzaw6qq5osnzm7wistid.onion/wiki/Distributed_Denial_of_Secrets",
    title: "Leaked Documents",
    text: "Distributed Denial of Secrets",
    status: "Online",
  },
  {
    href: "https://ddosecrets.com",
    title: "Leaked Documents",
    text: "Distributed Denial of Secrets (Clearnet)",
    status: "Online",
  },
  {
    href: "https://www.occrp.org/en",
    title: "Organized Crime and Corruption Reporting Project",
    text: "Organized Crime and Corruption Reporting Project",
    status: "Online",
  },
  {
    href: "https://vault.fbi.gov",
    title: "Official FBI Vault",
    text: "FBI - The Vault",
    status: "Online",
  },
  {
    href: "http://deeeepv4bfndyatwkdzeciebqcwwlvgqa6mofdtsvwpon4elfut7lfqd.onion",
    title: "Directory Of Tor Hidden Services",
    text: "Deep Link Onion Dir",
    status: "Online",
  },
  {
    href: "https://tor.wiki/",
    title: "Tor Wiki",
    text: "Tor Wiki",
    status: "Online",
  },
  {
    href: "http://toponiibv4eo4pctlszgavni5ajzg7uvkd7e2xslkjmtcfqesjlsqpid.onion/",
    title: "Open voting on TOR sites",
    text: "Open Wiki",
    status: "Online",
  },
  {
    href: "http://xmh57jrknzkhv6y3ls3ubitzfqnkrwxhopf5aygthi7d6rplyvk3noyd.onion/cgi-bin/omega/omega",
    title: "Search Engine",
    text: "Torch",
    status: "Online",
  },
];

const sortedLinks = linksData.sort((a, b) => a.text.localeCompare(b.text));

const generateListItem = (linkData) => {
  const isOnionLink = linkData.href.includes(".onion");
  const isClearnetLink = !isOnionLink;

  return (
    <li className="Link wrapper" key={linkData.text}>
      <a
        href={linkData.href}
        lang="en"
        title={linkData.title}
        target="_blank"
        rel="noreferrer"
      >
        {linkData.text}
        {isOnionLink && (
          <span className="onion">
            <img src={torOnionLogo} alt="" />
          </span>
        )}
        {isClearnetLink && (
          <span className="clearnet">
            <img src={internetIcon} alt="" />
          </span>
        )}
        <span className="status">
          <img
            src={linkData.status === "Online" ? checkIcon : crossIcon}
            alt=""
          />
        </span>
      </a>
      {/* Display the status */}
    </li>
  );
};

const Header = () => {
  return (
    <nav role="navigation">
      <header className="header" id="header">
        <ul className="nav-links">{sortedLinks.map(generateListItem)}</ul>
      </header>
    </nav>
  );
};

export default Header;
