import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "../../styles/scss/global/global.scss";
import * as Global from "./global.router";
import * as Pages from "./pages.router";

const { Layout, Loading, NoPage } = Global;
const { Home } = Pages;

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
